import { Product } from "../types/Product"
import { Branch } from "../types/Branch"
import { isDateBetween, isSameDay, Weekday } from "./dateUtils"

const days = ["so", "mo", "di", "mi", "do", "fr", "sa"]

function getWeekDay(date: Date) {
  return days[date.getDay()]
}

export function isArticleAvailableForDate(article: Product, date: Date | null) {
  if (!date) return false
  return article.days.indexOf(getWeekDay(date)) !== -1
}

export function getOpeningHoursForDate(branch: Branch, date: Date) {
  switch (date.getDay()) {
    case Weekday.Saturday:
      return branch.openingHours.saturday
    case Weekday.Sunday:
      return branch.openingHours.sunday
    default:
      return branch.openingHours.week
  }
}

export function isWithinOpeningHours(branch: Branch, date?: Date | null) {
  if (!date) return false
  const openingHours = getOpeningHoursForDate(branch, date)
  if (!openingHours) return false
  const hour = date.getHours()
  const minutes = date.getMinutes()
  const minutesOfDay = hour * 60 + minutes
  const match = openingHours.find(({ from, to }) => {
    return minutesOfDay >= from.minutesOfDay && minutesOfDay <= to.minutesOfDay
  })
  return !!match
}

const blacklisted = [
  new Date(2023, 11, 25),
  new Date(2023, 11, 26),
  new Date(2024, 0, 1),
]
const from = new Date(2020, 11, 23, 16)
const to = new Date(2020, 11, 28, 11)
export function isClosed(date: Date): string | false {
  if (isDateBetween(from, to, date))
    return "Bestellungen sind wieder ab dem 29.12 möglich"
  if (blacklisted.find((closed) => isSameDay(date, closed)))
    return "Bestellungen an diesem Tag sind leider nicht möglich"
  return false
}

export function isWeekend(date: Date) {
  switch (date.getDay()) {
    case Weekday.Friday:
      return date.getHours() >= 17
    case Weekday.Saturday:
    case Weekday.Sunday:
      return true
    default:
      return false
  }
}
