import { makeStyles } from "@material-ui/core/styles"
import { primaryColor } from "./styleUtils"

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    // width: '100%',
    minWidth: 240,
    [theme.breakpoints.down(780)]: {
      width: "100%",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  // button: {
  //   backgroundColor: primaryColor,
  //   '&:hover': {
  //     backgroundColor: primaryColor,
  //     opacity: .7,
  //   }
  // }
}))
