import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { useDispatch } from "react-redux"
import { disableTouchFeedback } from "../../components/input/inputStyles"
import { Product } from "../../types/Product"
import { primaryColor } from "../../utils/style/styleUtils"
import { cartActions, useCartItem } from "../../store/cart/cartSlice"
import { settingsActions } from "../../store/settings/settingsSlice"

interface CountSelectorProps {
  disabled?: boolean
  article: Product
}

let debugClicks = 0

export function CountSelector({ article, disabled }: CountSelectorProps) {
  const dispatch = useDispatch()
  const count = useCartItem(article.number)

  useEffect(() => {
    if (disabled && count > 0) {
      dispatch(
        cartActions.setProductQuantity({
          count: 0,
          productId: article.number,
        })
      )
    }
  }, [disabled, count, article])

  return (
    <Container>
      <ControlContainer>
        <button
          disabled={disabled}
          onClick={() => {
            dispatch(
              cartActions.addProductQuantity({
                productId: article.number,
                count: -1,
              })
            )
            if (article.number === 251) {
              debugClicks++
              if (debugClicks >= 5) {
                dispatch(
                  settingsActions.updateSettings({
                    showSettings: true,
                  })
                )
              }
            }
          }}
        >
          −
        </button>
        <input
          type={"number"}
          min={0}
          value={count}
          disabled={disabled}
          onFocus={(event) => {
            const element = event.currentTarget
            element.type = "text"
            element.setSelectionRange(0, element.value.length)
            element.type = "number"
          }}
          onChange={(event) => {
            dispatch(
              cartActions.setProductQuantity({
                productId: article.number,
                count: parseInt(event.currentTarget.value) || 0,
              })
            )
            debugClicks = 0
          }}
        />
        <button
          disabled={disabled}
          onClick={() => {
            dispatch(
              cartActions.addProductQuantity({
                productId: article.number,
                count: 1,
              })
            )

            debugClicks = 0
          }}
        >
          +
        </button>
      </ControlContainer>
    </Container>
  )
}

const ControlContainer = styled.div`
  background-color: #ededed;
  display: inline-flex;
  align-items: center;
  input {
    background-color: unset;
  }
`

const Container = styled.div`
  button,
  input {
    ${disableTouchFeedback};
    border: none;
    text-align: center;
  }

  button {
    cursor: pointer;
    background-color: ${primaryColor};
    color: white;
    border-radius: 3px;
    padding: 6px 16px;
    &:hover {
      opacity: 0.7;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  input {
    width: 32px;
    margin-left: 4px;
    margin-right: 4px;
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
