import React, { useMemo, useState } from "react"
import styled from "@emotion/styled"
import {
  allProductCategories,
  ProductCategory,
  ProductCategoryInfo,
} from "../../types/ProductCategory"
import { specialFont } from "../../utils/style/styleUtils"
import { useProducts } from "../../services/useProducts"
import { Article, ArticleGrid } from "./Article"

interface ArticleSelectionProps {
  selectedDate: Date | null
}

function ArticleSelection(props: ArticleSelectionProps) {
  const products = useProducts()

  // if (process.env.NODE_ENV === "development") {
  //   products.length = 4
  // }

  const [category, setCategory] = useState<ProductCategory>(
    ProductCategory.BREAD
  )
  const filtered = useMemo(() => {
    return products
      .filter((p) => {
        if (category === ProductCategory.BREAD) {
          return (
            p.category === ProductCategory.BREAD ||
            p.category === ProductCategory.BAGUETTE
          )
        } else if (category === ProductCategory.BUN) {
          return p.category === ProductCategory.BUN
        } else if (category === ProductCategory.SWEETS) {
          return p.category === ProductCategory.SWEETS
        }
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
  }, [category, products])
  return (
    <div>
      <FilterBar>
        <Tags>
          <Tag
            active={category === ProductCategory.BREAD}
            onClick={() => {
              setCategory(ProductCategory.BREAD)
            }}
          >
            Brote
          </Tag>
          <Tag
            active={category === ProductCategory.BUN}
            onClick={() => {
              setCategory(ProductCategory.BUN)
            }}
          >
            Brötchen
          </Tag>
          <Tag
            active={category === ProductCategory.SWEETS}
            onClick={() => {
              setCategory(ProductCategory.SWEETS)
            }}
          >
            Süßes
          </Tag>
        </Tags>
      </FilterBar>
      {filtered && (
        <ArticleGrid>
          {filtered.map((p) => (
            <Article
              key={p.number}
              article={p}
              selectedDate={props.selectedDate}
            />
          ))}
        </ArticleGrid>
      )}
    </div>
  )
}

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const filterCategories: ProductCategoryInfo[] = [
  // { title: "Alle" },
  ...allProductCategories,
]

const Tags = styled.div`
  ${specialFont};
  font-size: 1.8rem;
  display: flex;
`

const Tag = styled.div<{ active?: boolean }>`
  margin-right: 14px;
  text-decoration: ${(props) => props.active && "underline"};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

export default ArticleSelection
