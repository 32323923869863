import React from "react"
import styled from "@emotion/styled"
import CartItemTable from "./CartItemTable"
import { useCartItems } from "../../store/cart/cartHooks"

interface SummaryProps {}

function Summary(props: SummaryProps) {
  const items = useCartItems()
  if (!items?.length) return null
  return (
    <TableWrapper>
      <CartItemTable items={items} />
      <EndNote>* Preisangaben ohne Gewähr</EndNote>
      {/*<pre>{JSON.stringify(items, null, 2)}</pre>*/}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  background-color: #ededed;
  padding: 24px;
  border-radius: 4px;

  th {
    font-weight: bold;
  }

  tr td:first-of-type,
  tr th:first-of-type {
    padding-left: 0;
  }
  tr td:last-of-type,
  tr th:last-of-type {
    padding-right: 0;
  }
`

const EndNote = styled.small`
  margin-right: 12px;
  margin-top: 4px;
  display: block;
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  text-align: right;
`

export default Summary
