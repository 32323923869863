import React, { useCallback, useMemo } from "react"
import { useStyles } from "../utils/style/useStyles"
import DatePicker, { DatePickerProps } from "../components/input/DatePicker"
import {
  formActions,
  useForm,
  useSelectedBranch,
} from "../store/form/formSlice"
import { useDispatch } from "react-redux"
import { addDays, findNextWeekday, Weekday } from "../utils/dateUtils"
import {
  getOpeningHoursForDate,
  isClosed,
  isWeekend,
  isWithinOpeningHours,
} from "../utils/domainUtils"

interface DaySelectionProps {}

function DaySelection(props: DaySelectionProps) {
  const classes = useStyles()
  const { date } = useForm((before, after) => before.date === after.date)
  const { branch } = useSelectedBranch()
  const dispatch = useDispatch()

  const onChange = useCallback((d: Date | null) => {
    dispatch(formActions.setDate(d || undefined))
  }, [])

  const pickerProps = useMemo<Partial<DatePickerProps>>(() => {
    const now = new Date()
    const isEvening = now.getHours() >= 17
    let minDate
    if (isWeekend(now)) {
      // next available date will be tuesday
      minDate = findNextWeekday(now, Weekday.Tuesday)
    } else {
      // allow the next date, if not evening
      minDate = addDays(now, isEvening ? 2 : 1)
    }
    return {
      minDate,
      maxDate: addDays(new Date(), 30),
      shouldDisableDate: (date) => {
        if (!date || !branch) return false
        // disable if there are no hours for this day
        const hours = getOpeningHoursForDate(branch, date)
        if (!hours.length) return true
        if (isClosed(date)) {
          return true
        }
        return false
      },
      validateDate: (date) => {
        if (!date) return undefined
        if (!branch) return undefined
        if (!isWithinOpeningHours(branch, date))
          return "Die Abholzeit liegt außerhalb der Öffnungszeiten"
        const closed = isClosed(date)
        if (closed) return closed
        return undefined
      },
    }
  }, [branch])

  return (
    <DatePicker
      value={date}
      onChange={onChange}
      className={classes.formControl}
      disabled={!branch}
      required
      {...pickerProps}
    />
  )
}

export default DaySelection
