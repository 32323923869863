import React from "react"
import styled from "@emotion/styled"

interface LogoProps {}

function Logo(props: LogoProps) {
  return (
    <a href={"http://www.baeckerei-schmitz.de/"}>
      <LogoImage
        src="http://www.baeckerei-schmitz.de/demos/restaurant/images/logo.png"
        alt="Logo"
      />
    </a>
  )
}

const LogoImage = styled.img`
  //position:absolute;
  //right: 0;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
`

export default Logo
