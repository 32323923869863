import React, { useState } from "react"
import styled from "@emotion/styled"
import { Button, TextField } from "@material-ui/core"
import { useBindState } from "../../utils/hooks/useBindState"
import { useDispatch } from "react-redux"
import {
  settingsActions,
  useSettings,
} from "../../store/settings/settingsSlice"
import { PublishButton } from "./PublishButton"

interface DevMenuProps {}

function DevMenu(props: DevMenuProps) {
  const { email, showSettings } = useSettings()
  const [toggled, setToggled] = useState(false)
  const mailState = useBindState(email)
  const dispatch = useDispatch()
  if (!showSettings) return null
  return (
    <>
      {toggled && (
        <Container>
          <TextField
            value={email}
            {...mailState}
            variant={"outlined"}
            size={"small"}
            type="text"
            label={"Empfänger"}
            placeholder={"Formular Empfängers"}
          />
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              dispatch(
                settingsActions.updateSettings({
                  email: mailState.value,
                })
              )
              setToggled(false)
              alert(
                "Ok. Die Eingabe bleibt auch beim Neuladen der Seite gespeichert."
              )
            }}
          >
            Speichern
          </Button>
        </Container>
      )}
      <ToggleContainer>
        <PublishButton />
        <Toggle onClick={() => setToggled((s) => !s)}>Einstellungen</Toggle>
      </ToggleContainer>
    </>
  )
}

const ToggleContainer = styled.div`
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 24px;
  right: 24px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.7);
`

const Toggle = styled.div`
  margin-top: 24px;
`

const Container = styled.div`
  color: #004085;
  background-color: #ebebeb;
  border-color: #b8daff;
  padding: 24px 24px 12px;
  p {
    margin-top: 8px;
  }
  button {
    margin-left: 24px;
  }
`

export default DevMenu
