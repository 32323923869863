import { useProducts } from "../../services/useProducts"
import { useMemo } from "react"
import { notNull } from "../../utils/jsUtils"
import { Product } from "../../types/Product"
import { useCart } from "./cartSlice"

export interface CartItem {
  product: Product
  quantity: number
}

export function useCartItems(): CartItem[] {
  const products = useProducts()
  const cart = useCart()
  return useMemo(() => {
    if (!cart) return undefined
    return Object.entries(cart)
      .map(([id, quantity]) => {
        const product = products.find(
          (p) => p.number == ((id as unknown) as number)
        )
        if (!product) return undefined
        return {
          product,
          quantity,
        }
      })
      .filter(notNull)
  }, [cart, products]) as CartItem[]
}

export function useSimpleCartItems() {
  const items = useCartItems()
  return useMemo(() => {
    return items?.map((item) => {
      return {
        number: item.product.number,
        name: item.product.name,
        quantity: item.quantity,
      }
    })
  }, [items])
}
