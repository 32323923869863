import React, { useMemo, useState } from "react"
import styled from "@emotion/styled"
import { truncate } from "../../utils/jsUtils"

interface ExtractProps {
  children: string
  maxLength: number
}

const prefix = "..."

function Extract({ children: text, maxLength }: ExtractProps) {
  const [open, setOpen] = useState(false)
  const extract = useMemo(() => {
    if (text.length < maxLength) return text
    return truncate(text, maxLength - prefix.length) + prefix
  }, [text, maxLength])
  const hasMoreText = extract.length !== text.length
  const canBeExpanded = hasMoreText && !open
  return (
    <span>
      {open ? text : extract}
      {canBeExpanded && (
        <MoreButton
          onClick={() => {
            setOpen(true)
          }}
        >
          mehr
        </MoreButton>
      )}
    </span>
  )
}

const MoreButton = styled.a`
  margin-left: 4px;
  cursor: pointer;
`

export default Extract
