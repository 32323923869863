import { useState, useMemo, useCallback } from "react"
import { useDispatch } from "react-redux"
import { settingsActions } from "./settingsSlice"

export function useShowDevMenu() {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(
      settingsActions.updateSettings({
        showSettings: true,
      })
    )
  }, [dispatch])
}

export function useDevMenuToggle(requiredTriggers = 5) {
  const [remaining, setRemaining] = useState(requiredTriggers)
  const showMenu = useShowDevMenu()
  return useMemo(
    () => ({
      trigger() {
        setRemaining((prev) => {
          if (prev <= 1) {
            setTimeout(() => {
              showMenu()
            })
            setRemaining(requiredTriggers)
          } else {
            return prev - 1
          }
        })
      },
    }),
    [setRemaining]
  )
}
