import React from "react"
import MainLayout from "../components/layout/MainLayout"
import ArticleSelection from "../views/articles/ArticleSelection"
import Section from "../views/Section"
import BranchInput from "../sections/BranchInput"
import DaySelection from "../sections/DaySelection"
import ContactInput from "../sections/contact/ContactInput"
import FlexSection from "../views/FlexSection"
import Summary from "../sections/summary/Summary"
import DevMenu from "../sections/dev/DevMenu"
import { isQa } from "../config/config"
import { useSelectedDate } from "../store/form/formSlice"
import styled from "@emotion/styled"
import Logo from "../components/layout/Logo"
import BranchInfo from "../sections/BranchInfo"
import WeekendInfo from "../views/WeekendInfo"
import { useDevMenuToggle } from "../store/settings/settingsActions"

interface OrderPageProps {}

function OrderPage(props: OrderPageProps) {
  const date = useSelectedDate()
  const { trigger } = useDevMenuToggle()
  return (
    <MainLayout>
      {isQa && <DevMenu />}
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Alert>Am 25., 26. und 1.1. sind unsere Filialen geschlossen.</Alert>
      <Title onClick={trigger}>Brötchenbestellung</Title>
      <p>
        Bestellen Sie Ihre Brötchen über unser Online-Formular ganz einfach vor.
      </p>
      <Section title={"Filiale & Datum"} initialOpen>
        <WeekendInfo />
        <div>
          <BranchInput />
          <DaySelection />
        </div>
        <BranchInfo />
      </Section>
      <Section title={"Produkte"} initialOpen>
        {date && <ArticleSelection selectedDate={date} />}
        {!date && <p>Bitte wählen Sie zunächst ein Abholdatum aus.</p>}
      </Section>
      <FlexSection title={"Abschicken"}>
        <ContactInput />
        <Summary />
      </FlexSection>
    </MainLayout>
  )
}

const Alert = styled.div`
  margin-bottom: 1em;
  background-color: #e7272d;
  color: white;
  padding: 1em 1em;
  line-height: 1.4;
`

const Title = styled.h1`
  user-select: none;
`

const LogoContainer = styled.div`
  position: relative;
`

export default OrderPage
