import React from "react"
import { Branch } from "../types/Branch"
import { formatOpeningHours, formatPhone } from "../utils/formatUtils"
import styled from "@emotion/styled"
import { useSelectedBranch } from "../store/form/formSlice"
import { mediaMobile } from "../utils/style/mediaUtils"

interface BranchInfoProps {}

function BranchInfo(props: BranchInfoProps) {
  const { branch } = useSelectedBranch()
  if (!branch) return null
  const { openingHours } = branch
  return (
    <Row>
      <div>
        <Table>
          <thead>
            <tr>
              <th>{branch.name}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{branch.address}</td>
            </tr>
            <tr>
              <td>Telefon: {formatPhone(branch.phone)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
        <Table>
          <thead>
            <tr>
              <th colSpan={3}>Öffnungszeiten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Mo - Fr</th>
              <td>{formatOpeningHours(openingHours.week) || "geschlossen"}</td>
            </tr>
            <tr>
              <th>Sa</th>
              <td>
                {formatOpeningHours(openingHours.saturday) || "geschlossen"}
              </td>
            </tr>
            <tr>
              <th>So</th>
              <td>
                {formatOpeningHours(openingHours.sunday) || "geschlossen"}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -14px;
  > div {
    margin: 14px;
  }
`

const Table = styled.table`
  th {
    text-align: left;
  }
  th,
  td {
    padding-right: 12px;
  }
`

export default BranchInfo
