import { ChangeEvent, useCallback, useState } from "react"

type InitialState<T> = T | (() => T)

export function useBindState(initial: InitialState<string> = "") {
  const [value, setValue] = useState(initial)
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
  }, [])
  return {
    value,
    onChange,
  }
}
