import React from "react"
import { WithChildren } from "../utils/types/reactTypes"
import styled from "@emotion/styled"

export interface SectionProps extends WithChildren {
  initialOpen?: boolean
  title: string
  ContentWrapper?: any
}

function Section(props: SectionProps) {
  // const [open, setOpen] = useState(props.initialOpen || true)
  const ContentWrapper = props.ContentWrapper || DefaultContentWrapper
  return (
    <SectionContainer>
      <Title
      // onClick={() => setOpen(o => !o)}
      >
        {props.title}
        {/*<Arrow>{open ? "↓" : "↑"}</Arrow>*/}
      </Title>
      <ContentWrapper>{props.children}</ContentWrapper>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  margin-top: 12px;
`

const DefaultContentWrapper = styled.div``

const Arrow = styled.span`
  font-size: .6em;
  margin-top: 8px
  vertical-align: text-top;
`

const Title = styled.h2``

export default Section
