import React, { memo, useEffect, useState } from "react"
import { Info } from "../components/notifications/Warning"
import { isWeekend } from "../utils/domainUtils"

interface WeekendInfoProps {}

function WeekendInfo(props: WeekendInfoProps) {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(isWeekend(new Date()))
  }, [])
  if (!visible) return null
  return (
    <Info>
      <b>Hinweis:</b> Leider können zurzeit noch keine Bestellungen am
      Wochenende angenommen werden. Bestellungen für Samstag, Sonntag und Montag
      müssen jeweils bis Freitag um 17 Uhr erfolgen.
    </Info>
  )
}

export default memo(WeekendInfo)
