import React, { useMemo } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { CartItem } from "../../store/cart/cartHooks"
import { formatPrice } from "../../utils/formatUtils"

interface CartItemTableProps {
  items: CartItem[]
}

function CartItemTable({ items }: CartItemTableProps) {
  const total = useMemo(() => {
    return Object.values(items).reduce((sum, item) => {
      return sum + item.product.price * item.quantity
    }, 0)
  }, [items])
  return (
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell align={"left"}>Produkt</TableCell>
          <TableCell align={"right"}>Anzahl</TableCell>
          <TableCell align={"right"}>Preis *</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(({ product, quantity }) => (
          <TableRow key={product.number}>
            <TableCell>{product.name}</TableCell>
            <TableCell align={"right"}>{quantity}x</TableCell>
            <TableCell align={"right"}>
              {formatPrice(quantity * product.price)}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3} align={"right"}>
            <b>{formatPrice(total)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default CartItemTable
