import React, { useCallback, useState } from "react"
import { Button } from "@material-ui/core"
import { fetchApi } from "../../services/fetchApi"

interface PublishButtonProps {}

export function PublishButton(props: PublishButtonProps) {
  const [fetching, setFetching] = useState(false)
  const onSubmit = useCallback(() => {
    setFetching(true)
    fetchPublish()
      .then(() => {
        // setFetching(false)
      })
      .catch(() => {
        setFetching(false)
      })
  }, [])
  return (
    <Button
      variant="contained"
      color={"secondary"}
      onClick={onSubmit}
      disabled={fetching}
    >
      Veröffentlichen
    </Button>
  )
}

function fetchPublish() {
  return fetchApi("/publish.php")
}
