import React, { CSSProperties, HTMLAttributes, useMemo } from "react"
import { FormState } from "../store/form/formSlice"
import { Stylable } from "../utils/types/reactTypes"
import { formatDateTime, formatPrice, formatUnits } from "../utils/formatUtils"
import { CartItem } from "../store/cart/cartHooks"

interface MailTemplateProps extends Stylable {
  form: FormState
  items: CartItem[]
}

function MailTemplate({ form, items }: MailTemplateProps) {
  const total = useMemo(() => {
    return Object.values(items).reduce((sum, item) => {
      return sum + item.product.price * item.quantity
    }, 0)
  }, [items])
  return (
    <div>
      <Table>
        <tbody>
          {form.branch && (
            <tr>
              <Th>Filiale</Th>
              <Td>
                <div>{form.branch.name}</div>
                <div>{form.branch.address}</div>
              </Td>
            </tr>
          )}
          <tr>
            <Th>Abholdatum</Th>
            <Td>{formatDateTime(form.date)}</Td>
          </tr>
          <tr>
            <Th>Name</Th>
            <Td>{form.name}</Td>
          </tr>
          <tr>
            <Th>Telefon</Th>
            <Td>{form.phone}</Td>
          </tr>
        </tbody>
      </Table>
      <h2>Bestellung</h2>
      <Table>
        <thead>
          <tr>
            <Th>#</Th>
            <Th>Artikel</Th>
            <Th>Einheit</Th>
            <Th>Anzahl</Th>
            <Th align={"right"}>Preis</Th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.product.number}>
              <Td>{item.product.number}</Td>
              <Td>{item.product.name}</Td>
              <Td>{formatUnits(item.product)}</Td>
              <Td>{item.quantity}</Td>
              <Td align={"right"}>
                {formatPrice(item.quantity * item.product.price)}
              </Td>
            </tr>
          ))}
          <tr>
            <Td colSpan={5} align={"right"}>
              <b>{formatPrice(total)}</b>
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const Table = (props: HTMLAttributes<HTMLTableElement>) => (
  <table cellPadding={2} cellSpacing={0} {...props} />
)
const Th = (props: any) => <th style={tdThStyle} align={"left"} {...props} />
const Td = (props: any) => <td style={tdThStyle} {...props} />

const tdThStyle: CSSProperties = {
  verticalAlign: "top",
  paddingRight: 12,
}

export default MailTemplate
