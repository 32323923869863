import { Product } from "../types/Product"
import { Branch, OpeningHours, Timespan } from "../types/Branch"
import { EMPTY_STRING } from "./jsUtils"

export function formatPrice(cent: number) {
  return cent.toFixed(2).replace(".", ",") + " €"
}

export function formatBranch(branch: Branch) {
  return `${branch.name} (${branch.address})`
}

export function formatDateTime(d: Date | null) {
  return d && `${_formatDate(d)}, ${_formatTime(d)} Uhr`
}

function _formatDate(d: Date) {
  return `${z(d.getDate())}.${z(d.getMonth() + 1)}.${d.getFullYear()}`
}

function _formatTime(d: Date) {
  return `${z(d.getHours())}:${z(d.getMinutes())}`
}

function z(n: number) {
  return n > 9 ? n.toString() : `0${n}`
}

export function formatUnits(article: Product) {
  if (article.weight) return article.weight + "g"
  else if (article.units === 1) return "Stück"
  else return article.units + " Stücke"
}

export function toUpperFirst(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function formatPhone(number: string) {
  if (!number) return number
  return number.replace(/ /g, "").replace("/", " / ")
}

export function formatTimespan({ from, to }: Timespan) {
  return `${z(from.hour)}:${z(from.minute)} - ${z(to.hour)}:${z(to.minute)}`
}

export function formatOpeningHours(o?: OpeningHours) {
  if (!o?.length) return undefined
  return o.map(formatTimespan).join(", ") + " Uhr"
}
