import { mailScript } from "../config/config"

interface Options {
  html: string
  subject: string
  to?: string
}

export function sendMail(options: Options) {
  if (!mailScript) return Promise.reject(new Error("missing mail script url"))
  return fetch(mailScript, {
    method: "POST",
    body: JSON.stringify(options),
  }).then(requireOk)
}

export function requireOk(response: Response) {
  if (response.ok) {
    return response
  } else {
    throw new Error("response failed")
  }
}
