import React, { useCallback, useState } from "react"
import { Button } from "@material-ui/core"
import { renderToStaticMarkup } from "react-dom/server"
import MailTemplate from "../../html/MailTemplate"
import { sendMail } from "../../services/sendMail"
import { useForm } from "../../store/form/formSlice"
import { useCartItems } from "../../store/cart/cartHooks"
import styled from "@emotion/styled"
import { useSettings } from "../../store/settings/settingsSlice"
import { Warning } from "../../components/notifications/Warning"
import { css } from "@emotion/core"

interface SubmitButtonProps {
  disabled: boolean
}

function SubmitButton({ disabled: propsDisabled }: SubmitButtonProps) {
  const form = useForm()
  const items = useCartItems()
  const settings = useSettings()

  const [submitState, setSubmitState] = useState<"fetching" | "error" | "ok">()

  const onSubmit = useCallback(() => {
    const subject = `Neue 🍞 Bestellung für "${form.branch?.name?.trim()}" von "${form.name?.trim()}"`
    const html = renderToStaticMarkup(
      <MailTemplate form={form} items={items} />
    )
    setSubmitState("fetching")
    sendMail({ subject, html, to: settings.email || undefined })
      .then(() => setSubmitState("ok"))
      .catch((reason) => {
        console.error(reason)
        setSubmitState("error")
      })
  }, [form, items, settings])

  const disabled =
    propsDisabled || submitState === "fetching" || submitState === "ok"

  return (
    <div>
      {settings.email && (
        <Warning css={warningCss}>
          <b>Achtung</b>: Aktuell eingestellter Empfänger:{" "}
          <i>{settings.email}</i>
        </Warning>
      )}
      <Button
        disabled={disabled}
        type={"button"}
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        Vorbestellen
      </Button>
      {submitState === "error" && (
        <Feedback>Ein Fehler ist aufgetreten.</Feedback>
      )}
      {submitState === "ok" && (
        <Feedback>Vielen Dank für Ihre Vorbestellung.</Feedback>
      )}
    </div>
  )
}

const Feedback = styled.div`
  margin-top: 12px;
`

const warningCss = css`
  margin-bottom: 12px;
`

export default SubmitButton
