import React from "react"
import { Global } from "@emotion/core"
import { mainLayoutCss } from "./mainLayoutCss"
import styled from "@emotion/styled"
import { mediaMobile } from "../../utils/style/mediaUtils"

interface MainLayoutProps {
  children: any
}

function MainLayout(props: MainLayoutProps) {
  return (
    <Container>
      <Global styles={mainLayoutCss} />
      {props.children}
    </Container>
  )
}

const Container = styled.div`
  max-width: 1170px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px auto 84px;
  ${mediaMobile} {
    margin-top: 8px;
  }
`

export default MainLayout
