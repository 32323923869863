import { css } from "@emotion/core"

export const baseInputCss = css`
  border: none;
  background-color: #e10713;
  border-radius: 3px;
`

export const disableTouchFeedback = css`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
`
