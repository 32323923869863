export enum ProductCategory {
  BREAD = "Brot",
  BUN = "Brötchen",
  BAGUETTE = "Baguette",
  SWEETS = "Süßes",
}

export interface ProductCategoryInfo {
  title: string
}

export const productCategories: Record<ProductCategory, ProductCategoryInfo> = {
  [ProductCategory.BREAD]: {
    title: ProductCategory.BREAD,
  },
  [ProductCategory.BUN]: {
    title: ProductCategory.BUN,
  },
  [ProductCategory.BAGUETTE]: {
    title: ProductCategory.BAGUETTE,
  },
  [ProductCategory.SWEETS]: {
    title: ProductCategory.SWEETS,
  },
}

export const allProductCategories = Object.values(productCategories)
