import React from "react"
import styled from "@emotion/styled"
import Section, { SectionProps } from "./Section"
import { mediaMobile } from "../utils/style/mediaUtils"

interface FlexSectionProps extends SectionProps {}

function FlexSection(props: FlexSectionProps) {
  return <Section {...props} ContentWrapper={ContentWrapper} />
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  ${mediaMobile} {
    grid-template-columns: 1fr;
    > div:first-of-type {
      order: 2;
    }
  }
`

export default FlexSection
