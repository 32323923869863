import React from "react"
import { FormControl, TextField } from "@material-ui/core"
import { useStyles } from "../../utils/style/useStyles"
import { formActions, useForm } from "../../store/form/formSlice"
import { useDispatch } from "react-redux"
import SubmitView from "../submit/SubmitView"
import { Warning } from "../../components/notifications/Warning"

interface ContactInputProps {}

function ContactInput(props: ContactInputProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { name, phone } = useForm()
  return (
    <div>
      <p>
        Teilen Sie uns noch Ihren Namen und Ihre Telefonnummer mit, damit wir
        Sie bei Rückfragen erreichen können.
      </p>
      <FormControl className={classes.formControl}>
        <TextField
          name={"name"}
          value={name}
          onChange={(event) => {
            dispatch(formActions.setName(event.target.value))
          }}
          size={"small"}
          label={"Ihr Name"}
          placeholder={"Max Mustermann"}
          variant={"outlined"}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          name={"phone"}
          value={phone}
          onChange={(event) => {
            dispatch(formActions.setPhone(event.target.value))
          }}
          type={"tel"}
          size={"small"}
          label={"Telefon"}
          placeholder={"Ihre Telefonnummer"}
          variant={"outlined"}
          required
        />
      </FormControl>
      <SubmitView />
    </div>
  )
}

export default ContactInput
