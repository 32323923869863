export function truncate(str: string, maxLength: number) {
  let truncated = str.substr(0, maxLength)
  truncated = truncated.substr(
    0,
    Math.min(truncated.length, truncated.lastIndexOf(" "))
  )
  return truncated
}

export function notNull(obj: any) {
  return !!obj
}

export function toInt(str: string) {
  return parseInt(str)
}

export const EMPTY_STRING = ""
