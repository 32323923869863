import React, { ChangeEvent, useState } from "react"
import { css } from "@emotion/core"
import PrivacyCheckbox from "../contact/PrivacyCheckbox"
import { useDemoValues } from "../../config/config"
import { useSelector } from "react-redux"
import { validFormSelector } from "../../store/selectors"
import SubmitButton from "./SubmitButton"

interface SubmitViewProps {}

function SubmitView(props: SubmitViewProps) {
  const [accepted, setAccepted] = useState<boolean>(useDemoValues)
  const isValidForm = useSelector(validFormSelector)
  const disabled = !isValidForm || !accepted

  return (
    <>
      <PrivacyCheckbox
        checked={accepted}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setAccepted(event.currentTarget.checked)
        }}
      />
      <div
        css={css`
          margin-top: 12px;
        `}
      >
        <SubmitButton disabled={disabled} />
        {/*<MailTemplatePreview>*/}
        {/*  <MailTemplate*/}
        {/*    // css={css`*/}
        {/*    //   display: none;*/}
        {/*    // `}*/}
        {/*    ref={template}*/}
        {/*  />*/}
        {/*</MailTemplatePreview>*/}
      </div>
    </>
  )
}

export default SubmitView
