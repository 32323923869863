import React, { memo } from "react"
import styled from "@emotion/styled"
import { formatPrice, toUpperFirst } from "../../utils/formatUtils"
import Extract from "../../components/text/Extract"
import { mediaMobile } from "../../utils/style/mediaUtils"
import { flexMargin, specialFont } from "../../utils/style/styleUtils"
import { Product } from "../../types/Product"
import { CountSelector } from "./CountSelector"
import { isArticleAvailableForDate } from "../../utils/domainUtils"

interface ArticleProps {
  article: Product
  selectedDate: Date | null
}

export const Article = memo(({ article, selectedDate }: ArticleProps) => {
  const imageSrc =
    article.image?.fluid.src ||
    "http://images.ctfassets.net/edwa2rhdv3t1/6aYecVpcDLeAC7wxRma0ZL/9950dbf7326ba1c9f48217f0618be3df/platzhalter.jpg?w=800&q=50"
  const weight = article.weight && `${article.weight}g`
  const units = article.units || 1
  const isAvailable = isArticleAvailableForDate(article, selectedDate)
  return (
    <ArticleContainer>
      <Title>{article.name}</Title>
      <ImageCol>
        <ImageContainer>
          <Image unavailable={!isAvailable} src={imageSrc} />
          {!isAvailable && (
            <CenteredMessage>
              Nur an diesen Tagen verfügbar:
              <br />
              {article.days.map(toUpperFirst).join(", ")}
            </CenteredMessage>
          )}
        </ImageContainer>
      </ImageCol>
      <InfoCol>
        <div>
          <span>
            <b>{formatPrice(article.price * article.units)}</b>
            <small>
              {" "}
              / {weight || (units > 1 ? units + " " : "") + "Stück"}
            </small>
          </span>
        </div>
        <CountSelector article={article} disabled={!isAvailable} />
      </InfoCol>
      <div>
        {article.description && (
          <Extract maxLength={128}>{article.description}</Extract>
        )}
      </div>
    </ArticleContainer>
  )
})

const CenteredMessage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -25px;
  font-weight: bold;
  font-size: 14px;
  z-index: 100;
  text-align: center;
`

export const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  ${mediaMobile} {
    grid-template-columns: 1fr;
  }
  grid-gap: 16px;
`

const ArticleContainer = styled.div`
  position: relative;
  max-width: 400px;
  ${mediaMobile} {
    max-width: unset;
  }
`

const Title = styled.div`
  ${specialFont};
  font-size: 1.6rem;
  opacity: 0.9;
  position: absolute;
  top: 0;
  z-index: 10;
  padding: 8px;
  color: white;
  margin-bottom: 8px;
  line-height: 1.1;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexMargin(8)};
`

const ImageCol = styled.div`
  //margin-right: 24px;
  ${mediaMobile} {
    flex: 100%;
  }
`

const InfoCol = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImageContainer = styled.div`
  position: relative;
  padding-bottom: 70%;
`

const Image = styled.div<{ src: string; unavailable: boolean }>`
  position:absolute;
  top: 0;border-radius: 4px;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.0)
    ), url("${(props) => props.src}");
  opacity: ${(props) => props.unavailable && "0.5"};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70%;
  width: 100%;
`
