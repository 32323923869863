import React, { useState, useMemo, useEffect } from "react"
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DateTimePickerProps,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import deLocale from "date-fns/locale/de"
import { TextField, TextFieldProps } from "@material-ui/core"

export interface DatePickerProps
  extends Pick<
    DateTimePickerProps,
    | "value"
    | "onChange"
    | "shouldDisableDate"
    | "required"
    | "maxDate"
    | "minDate"
    | "className"
    | "disabled"
  > {
  validateDate?: (date: Date | null) => string | undefined
}

function DatePicker({ validateDate, ...pickerProps }: DatePickerProps) {
  const [invalidMessage, setInvalid] = useState<string | undefined>(() =>
    validateDate?.(pickerProps.value as Date)
  )

  useEffect(() => {
    if (!validateDate) return
    setInvalid(validateDate(pickerProps.value as Date))
  }, [validateDate, pickerProps.value])

  const allProps = useMemo<Partial<DateTimePickerProps>>(() => {
    if (!validateDate) return pickerProps
    return {
      ...pickerProps,
      style: {
        position: "relative",
      },
      onAccept: (date) => {
        setInvalid(validateDate(date))
      },
    }
  }, [pickerProps, validateDate])

  const TextComponnet = useMemo(() => {
    // we need to create a new component to show errors
    return (props: TextFieldProps) => (
      <TextField
        {...props}
        helperText={invalidMessage || props.helperText}
        error={!!invalidMessage || props.error}
      />
    )
  }, [invalidMessage])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <DateTimePicker
        inputVariant={"outlined"}
        size={"small"}
        label="Abholzeit"
        format={"dd.MM.yyyy HH:mm"}
        cancelLabel={"Abbrechen"}
        minDateMessage={"Das Datum liegt zu weit zurück"}
        maxDateMessage={"Das Datum liegt zu weit in der Zukunft"}
        ampm={false}
        autoOk
        animateYearScrolling
        value={pickerProps.value}
        onChange={pickerProps.onChange}
        TextFieldComponent={TextComponnet}
        {...allProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
