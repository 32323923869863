import styled from "@emotion/styled"

const Notification = styled.div`
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  max-width: 800px;
`

export const Warning = styled(Notification)`
  background-color: #fffbeb;
  color: #947600;
`

export const Info = styled(Notification)`
  background-color: rgb(231, 39, 45);
  color: white;
`
