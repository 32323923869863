import React, { useMemo } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { useStyles } from "../utils/style/useStyles"
import { useBranches } from "../services/useBranches"
import { useSelectedBranch } from "../store/form/formSlice"
import { Branch } from "../types/Branch"

interface BranchInputProps {}

function BranchInput(props: BranchInputProps) {
  const classes = useStyles()
  const branches = useBranches()
  const { branch, setBranch } = useSelectedBranch()

  const orderedBranches = useMemo(() => {
    return (branches as BranchWithLabel[])
      ?.map((branch) => {
        const [street, zipAndCity] = branch.address.split(",")
        const [zip, city] = zipAndCity.trim().split(" ")
        return {
          ...branch,
          label: `${city}, ${street} (${branch.name})`,
        }
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
  }, [branches])

  return (
    <FormControl
      className={classes.formControl}
      variant={"outlined"}
      size={"small"}
      required
    >
      <InputLabel id="filiale">Filiale</InputLabel>
      <Select
        labelId="filiale"
        label={"Filiale"}
        value={branch?.name || ""}
        onChange={(event) => {
          const { value } = event.target
          setBranch(branches.find((b) => b.name === value))
        }}
      >
        {orderedBranches.map((b) => {
          return (
            <MenuItem key={b.name} value={b.name}>
              {b.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

interface BranchWithLabel extends Branch {
  label: string
}

export default BranchInput
