import { graphql, useStaticQuery } from "gatsby"
import { Product } from "../types/Product"

function mapItem(edge: any): Product {
  return {
    ...edge,
    description: edge.description?.description,
  }
}

export function useProducts(): Product[] {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProdukt {
        edges {
          node {
            category
            number
            name
            weight
            units
            image {
              fluid {
                src
              }
            }
            price
            days
            description {
              description
            }
          }
        }
      }
    }
  `)
  return data["allContentfulProdukt"]["edges"].map((e: any) =>
    mapItem(e["node"])
  )
}
