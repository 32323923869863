import { graphql, useStaticQuery } from "gatsby"
import { useDemoValues } from "../config/config"
import { useEffect } from "react"
import { useSelectedBranch } from "../store/form/formSlice"
import { Branch, OpeningHours, Timespan } from "../types/Branch"
import { toInt } from "../utils/jsUtils"

function mapEdge(edge: any): Branch {
  const result: Branch = { ...edge }

  result.openingHours = {
    week: createOpeningHours(edge.opening_hours_week),
    saturday: createOpeningHours(edge.opening_hours_sa),
    sunday: createOpeningHours(edge.opening_hours_so),
  }
  return result
}

function createOpeningHours(str: string): OpeningHours {
  // 06:30-13:00,14:30-17:00
  if (!str) return []
  return str.split(",").map((timespan) => {
    // 06:30-13:00
    const [from, to] = timespan.split("-").map((time) => {
      // 06:30
      const [hour, minute] = time.split(":").map(toInt)
      return { hour, minute, minutesOfDay: hour * 60 + minute }
    })
    return {
      from,
      to,
    }
  })
}

export function useBranches(): Branch[] {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFiliale {
        edges {
          node {
            id
            name
            address
            coordinates {
              lon
              lat
            }
            phone
            opening_hours_week
            opening_hours_sa
            opening_hours_so
          }
        }
      }
    }
  `)
  const branches = data["allContentfulFiliale"]["edges"]
    .map((e: any) => mapEdge(e["node"]))
    .filter(isValid)
    .filter(hasOpeningHours)

  if (useDemoValues) {
    const { setBranch } = useSelectedBranch()
    useEffect(() => {
      setBranch(branches[0])
    }, [])
  }

  return branches
}

function isValid(b: Branch) {
  return !!b.address
}

function hasOpeningHours({ openingHours }: Branch) {
  return (
    openingHours.week.length ||
    openingHours.sunday.length ||
    openingHours.saturday.length
  )
}
