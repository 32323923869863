import React from "react"
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import { css } from "@emotion/core"
import { CheckboxProps } from "@material-ui/core/Checkbox/Checkbox"

interface PrivacyCheckboxProps extends CheckboxProps {}

function PrivacyCheckbox(props: PrivacyCheckboxProps) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox css={css``} name="privacy" color="primary" {...props} />
        }
        label={
          <p
            css={css`
              font-size: 14px;
            `}
          >
            Ich stimme der Verarbeitung meiner Daten entsprechend der{" "}
            <a
              target={"_blank"}
              href={"http://www.baeckerei-schmitz.de/datenschutz.html"}
            >
              Datenschutzerklärung
            </a>{" "}
            zu
          </p>
        }
      />
    </FormGroup>
  )
}

export default PrivacyCheckbox
